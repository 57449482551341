<template>
  <div>
    <div class="select-none max-w-7xl mx-auto">
      <div v-if="disableLoading" class="font-bold text-body-1 text-center my-10">
        Nemáte žádné zakoupené služby
      </div>
      <table v-else class="w-full mt-12">
        <thead>
          <tr>
            <th class="pb-4">Vlastník</th>
            <th class="pb-4">Název nemovitosti</th>
            <th class="pb-4">Název</th>
            <th class="pb-4">Cena</th>
            <th class="pb-4">Doklad</th>
            <th class="pb-4">Vytvořena</th>
          </tr>
        </thead>
        <tbody class="table-fixed mt-4 w-full">
          <tr v-for="receipt in receipts" :key="receipt.id" class="border-t-2 text-17">
            <td class="py-4 text-body-4 font-bold">
              {{ receipt.customer }}
            </td>
            <td class="py-4 text-body-4 font-bold flex flex-col items-center">
              <span>{{ receipt.propertyInfo.type }}</span>
              <span>{{ receipt.propertyInfo.address }}</span>
            </td>
            <td class="py-4 text-body-4 font-bold">
              {{ receipt.description }}
            </td>
            <td class="py-4 text-body-4 font-bold">
              {{ `${receipt.amount.toString().slice(0, -2)} Kč` }}
            </td>
            <td class="py-4 font-bold cursor-pointer">
              <router-link :to="`/doklad/${receipt.id}`" target="_blank" class="flex gap-3 justify-centertext-body-1 hover:bg-blue-100 rounded p-4">
                <img width="26" height="26" src="/images/download 1.png" />
                <span>{{ receipt.ticket ? "Stvrzenka" : "Daňový doklad" }}</span>
              </router-link>
            </td>
            <td class="py-4 text-body-4 font-bold text-right">
              {{ receipt.createdAt.getDate() }}.
              {{ receipt.createdAt.getMonth() + 1 }}.
              {{ receipt.createdAt.getFullYear() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!loaded && !disableLoading" class="font-bold text-body-1 text-center my-10">
      Načítám data...
      <div class="grid justify-items-center">
        <img src="../../../public/images/dataLoading.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGIN } from "@/store/user/constants";
import { getAllInvoices } from "@/services/rest/services";
import _ from "lodash";

export default {
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("main", ["currentProperty"]),
  },
  data() {
    return {
      receipts: [],
      disableLoading: false,
      loaded: false,
      houseTour: [
        "1x 3D Model",
        "1x Profesionální fotografie",
        "1x Profesionální video",
      ],
    };
  },
  methods: {
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
  },
  async mounted() {
    this.receipts = await getAllInvoices();
    if (this.receipts.length > 0) {
      this.receipts = this.receipts.map(receipt => {
        return {
          ...receipt,
          propertyInfo: {
            type: receipt.property.name.split("-").slice(0, 2).join(" "),
            address: receipt.property.name.split("-").slice(2).join(" ")
          },
          createdAt: new Date(receipt.createdAt),
          ticket: this.houseTour.includes(receipt.description),
        }
      });
      
      this.receipts = _.sortBy(this.receipts, "createdAt").reverse();
      this.loaded = true;
    } else {
      this.disableLoading = true;
    }
  },
};
</script>

<style scoped>

  td:not(:first-child) {
    text-align: center;
  }

</style>
